<template>
  <div class="d-flex justify-start flex-fill flex-wrap px-4">
    <span
      @click="filterByColor(event_colors.canceled)"
      :class="{ 'green--text': event_colors.canceled === activeColor }"
      class="cursorPointer d-flex align-center flex-row mr-9"
    >
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.canceled"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.classWithNoInformationOrPresentation") }}
    </span>
    <span
      @click="filterByColor(event_colors.passed)"
      :class="{ 'green--text': event_colors.passed === activeColor }"
      class="cursorPointer d-flex align-center flex-row mr-9"
    >
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.passed"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.noConfirmation") }}
    </span>
    <span
      @click="filterByColor(event_colors.new)"
      :class="{ 'green--text': event_colors.new === activeColor }"
      class="cursorPointer d-flex align-center flex-row mr-9"
    >
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.new"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.confirmedByTeacher") }}
    </span>
    <span
      @click="filterByColor(event_colors.waiting_for_class)"
      :class="{ 'green--text': event_colors.waiting_for_class === activeColor }"
      class="cursorPointer d-flex align-center flex-row mr-9"
    >
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.waiting_for_class"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.confirmedByAdmin") }}
    </span>
    <span
      @click="filterByColor(event_colors.cancel)"
      :class="{ 'green--text': event_colors.cancel === activeColor }"
      class="cursorPointer d-flex align-center flex-row mr-9"
    >
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.cancel"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.canceled") }}
    </span>
    <span
      @click="filterByColor(event_colors.reserved)"
      class="cursorPointer d-flex align-center flex-row mr-9"
      :class="{ 'green--text': event_colors.reserved === activeColor }"
    >
      <v-sheet
        width="15px"
        height="15px"
        :color="event_colors.reserved"
        class="rounded-pill mr-2"
      ></v-sheet>
      {{ $_t("attribute.reservedClass") }}
    </span>
  </div>
</template>

<script>
import { event_colors } from "@/packages/admin/schema/calender/COLORS";

export default {
  name: "ClassStateColor",
  props: {
    showNew: {
      type: Boolean,
      default: true,
    },
    activeColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      event_colors,
    };
  },
  methods: {
    filterByColor(color) {
      this.$emit("filterState", color);
    },
  },
};
</script>

<style scoped></style>
