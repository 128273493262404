import { _t } from "@/tools/Utils";

const { required } = require("vuelidate/lib/validators");

export const ADD_HOMEWORK_TO_LESSON = {
  /* Form SCHEMA */
  schema: [
    {
      id: "homeworkSubject",
      label: "homeworkSubject",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "textarea",
    },
    {
      id: "homeworkDeadLine",
      label: "homeworkDeadLine",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "date",
    },
    {
      id: "sessionNumber",
      label: "sessionNumber",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      type: "select",
      text: "text",
      value: "value",
      items: [],
      show: true,
    },
    {
      id: "homeworkFiles",
      label: "homeworkFiles",
      placeholder: "",
      cols: 12,
      lg: 6,
      md: 12,
      sm: 12,
      hint: _t("attribute.zipHint"),
      accept: ".zip,.rar,.7zip",
      type: "file",
    },
  ],

  /* Form MODEL */
  model: {
    homeworkDeadLine: null,
    homeworkSubject: null,
    sessionNumber: null,
    homeworkFiles: null,
  },
  validations: {
    form: {
      homeworkDeadLine: {
        required,
      },
      homeworkSubject: {
        required,
      },
      sessionNumber: {
        required,
      },
      homeworkFiles: {},
    },
  },
};
