import { required } from "vuelidate/lib/validators";

export const ADD_NEW_HOMEWORK_SCHEMA = {
  schema: [
    {
      id: "sessionNumber",
      label: "sessionNumber",
      placeholder: "",
      show: true,
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "select",
      text: "text",
      value: "value",
      items: [],
    },
  ],

  /* Form MODEL */
  model: {
    sessionNumber: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      sessionNumber: { required },
    },
  },
};
