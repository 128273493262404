<template>
  <v-row>
    <v-col cols="12">
      <ClassesCalendar />
    </v-col>
  </v-row>
</template>

<script>
import ClassesCalendar from "@/packages/admin/components/classRoom/ClassesCalendar";
export default {
  name: "classRoomUpcoming",
  components: { ClassesCalendar },
};
</script>

<style scoped></style>
