<template>
  <div v-if="exams">
    <v-expansion-panels multiple tile flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>{{ $_t("attribute.exams") }}</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                dense
                outlined
                :label="$_t('attribute.search')"
                @input="filter({ search: $event })"
              />
            </v-col>
            <v-col cols="12" md="5">
              <v-select
                hide-details
                dense
                outlined
                :label="$_t('attribute.exam_type')"
                v-model="examType"
                :items="examTypes"
                @input="filter({ examType: $event })"
              />
            </v-col>
            <v-col cols="12" md="2" v-if="examIds.length">
              <ConfirmDelete @confirm="deleteExam">
                <template #activator>
                  <v-btn icon :color="'red'">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </ConfirmDelete>
            </v-col>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">{{ $_t("attribute.status") }}</th>
                      <th class="text-left">{{ $_t("attribute.score") }}</th>
                      <th class="text-left">
                        {{ $_t("attribute.destination") }}
                      </th>
                      <th class="text-left">
                        {{ $_t("attribute.exam_feedback") }}
                      </th>
                      <th class="text-left">
                        {{ $_t("attribute.exam_type") }}
                      </th>
                      <th class="text-left">{{ $_t("attribute.edit") }}</th>
                      <th class="text-left">{{ $_t("attribute.delete") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(exam, i) in filteredExams" :key="exam.examId">
                      <td data-label="#">{{ i + 1 }}</td>
                      <td data-label="State">{{ exam.examState }}</td>
                      <td data-label="Score">
                        {{ exam.score || "No score reported" }}
                      </td>
                      <td data-label="Destination lesson">
                        {{ exam.destination }}
                        <!--                          {{-->
                        <!--                            exam.destination.length > 30-->
                        <!--                              ? truncateString(exam.destination, 30)-->
                        <!--                              : exam.destination-->
                        <!--                          }}-->
                      </td>
                      <td data-label="Exam feedback">
                        {{ exam.examFeedback }}
                      </td>
                      <td data-label="Exam type">
                        {{ $_t(`attribute.examTypes.${exam.type}`) }}
                      </td>
                      <td data-label="Edit">
                        <v-btn
                          @click="
                            editExam({
                              ...exam,
                              classroomId: $route.params.id,
                              studentPublicKey: $route.params.student_id,
                            })
                          "
                          icon
                          :color="'primary'"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                      <td data-label="Delete">
                        <v-checkbox
                          color="red"
                          :value="exam.examId"
                          v-model="examIds"
                        ></v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <EditStudentExam
      :is-show-modal="isShowExamModal"
      :exam="exam"
      @close="cancel"
      @update="initClass"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditStudentExam from "@/packages/admin/components/classRoom/details/modal/EditStudentExam";
import { _t } from "@/tools/Utils";
import ConfirmDelete from "@/components/app/ConfirmDelete";

export default {
  name: "ClassStudentExam",
  components: { ConfirmDelete, EditStudentExam },
  data() {
    return {
      exam: {},
      filteredExams: [],
      examIds: [],
      isShowExamModal: false,
      examType: null,
      search: "",
      examTypes: [
        { text: _t("attribute.examTypes.All"), value: null },
        { text: _t("attribute.examTypes.Total"), value: "Total" },
        { text: _t("attribute.examTypes.TitleExam"), value: "TitleExam" },
        { text: _t("attribute.examTypes.SubTitleExam"), value: "SubTitleExam" },
      ],
    };
  },
  watch: {
    student(val) {
      this.filteredExams = val.exams;
    },
  },
  mounted() {
    this.filteredExams = this.student.exams;
  },
  computed: {
    ...mapGetters({
      student: "class/student",
      modal: "dialog/student",
      singleClass: "class/getClass",
    }),
    exams() {
      if (!this.student) return null;
      return this.student.exams;
    },
  },
  methods: {
    filter({ examType = this.examType, search = this.search }) {
      this.examType = examType;
      this.search = search;
      let filteredExams = examType
        ? this.exams.filter((exam) => exam.type === examType)
        : this.exams;
      filteredExams = search
        ? this.exams.filter((exam) =>
            exam.destination.toLowerCase().includes(search.toLowerCase())
          )
        : filteredExams;
      this.filteredExams = filteredExams;
    },
    editExam(exam) {
      console.log(exam);
      this.exam = exam;
      this.isShowExamModal = true;
    },
    async deleteExam() {
      const res = await this.$actions.deleteStudentExam({
        classroomId: this.$route.params.id,
        studentPublicKey: this.$route.query.studentPublicKey,
        examIds: [...this.examIds],
      });
      if (res) {
        this.examIds = [];
        await this.initClass();
      }
    },
    cancel() {
      this.isShowExamModal = false;
    },
    async initClass() {
      await this.$actions.getSingleClass({
        id: this.$route.params.id || this.singleClass.classroomId,
      });
      await this.$store.commit(
        "class/setStudent",
        this.singleClass.students.find(
          (s) => s.studentPublicKey === this.$route.query.studentPublicKey
        )
      );
    },
  },
};
</script>

<style scoped></style>
