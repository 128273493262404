<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.confirmClass")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        {{
          time.type === "confirm"
            ? $_t("attribute.confirmClassSure")
            : $_t("attribute.confirmRevertClassSure")
        }}
      </v-card-title>
      <v-card-title>
        <v-list-item-content>
          <v-list-item-title>
            {{
              `${$_t("attribute.classroomNumber")} : ${time.classroomNumber}`
            }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{
              `${$_date(time.start)} => ${getMainTime(
                time.start,
                "h:mm a"
              )} : ${getMainTime(time.end, "h:mm a")} `
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-card-title>
      <v-card-title>
        <v-textarea
          outlined
          v-model="message"
          :label="$_t('attribute.confirmMessage')"
          hide-details
        />
      </v-card-title>

      <v-card-actions>
        <v-col cols="12">
          <div class="d-flex justify-end mb-4">
            <v-btn color="primary" text @click="close">
              {{ $_t("attribute.close") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-4"
              @click="submit"
              :loading="is_loading"
            >
              {{ $_t("attribute.confirm") }}
            </v-btn>
          </div>
          <div v-if="orderIds.length">
            <v-text-field
              v-for="id in orderIds"
              :key="id.studentsReportLink"
              outlined
              readonly
              persistent-hint
              :hint="`link will expire at ${$_date(
                id.linksExpireDate,
                'long'
              )}`"
              :label="`${id.studentFullName} parent report link`"
              :value="`${origin}/parent/report/${id.studentsReportLink}`"
              prepend-icon="mdi-content-copy"
              @click:prepend="
                copyToClipboard(
                  `${origin}/parent/report/${id.studentsReportLink}`
                )
              "
            />
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";

export default {
  name: "ConfirmClassSessionModal",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isShowModal(val) {
      if (val) {
        console.log(this.time);
        this.message = this.time?.message || null;
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      role: "authentication/role",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
    origin() {
      return window.origin;
    },
  },
  data() {
    return {
      message: "",
      getMainTime,
      orderIds: [],
    };
  },
  methods: {
    async submit() {
      try {
        let res = null;
        if (this.time.type === "confirm") {
          res = await this.$actions.editTimeDetailOfClass({
            classroomId: this.time.classroomId,
            availableTimeId: this.time.availableTimeId,
            classroomNumber: this.time.classroomNumber,
            message: this.message,
            start: this.time.start / 1000,
            end: this.time.end / 1000,
            changesConfirmedByTeacher:
              this.time.role === "teacher"
                ? true
                : this.time.changesConfirmedByTeacher,
            changesConfirmedBySchedulingAdmin:
              this.time.role === "admin"
                ? true
                : this.time.changesConfirmedBySchedulingAdmin,
          });
          if (res) {
            this.orderIds = await this.$actions.generateReportForParents({
              classroomId: this.time.classroomId,
              availableTimeId: this.time.availableTimeId,
              start: this.time.start / 1000,
              end: this.time.end / 1000,
            });
          }
        } else {
          res = await this.$actions.editTimeDetailOfClass({
            classroomId: this.time.classroomId,
            availableTimeId: this.time.availableTimeId,
            classroomNumber: this.time.classroomNumber,
            message: this.message,
            start: this.time.start / 1000,
            end: this.time.end / 1000,
            changesConfirmedByTeacher: false,
            changesConfirmedBySchedulingAdmin: false,
          });
          this.close();
        }

        if (res) {
          this.$emit("update");
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.message = "";
      this.orderIds = [];
      this.$emit("close");
    },
    async copyToClipboard(url) {
      try {
        await navigator.clipboard.writeText(url);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>

<style scoped></style>
