<template>
  <v-dialog v-model="isShowVerifyModal" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.files") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon color="gray">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" v-for="file in files" :key="file.fileLink">
              <v-btn
                @click="
                  fileDownloadUrlDynamic(file.fileLink, 'getHomeworkFile')
                "
                color="primary"
                class="mr-4"
              >
                <v-icon>mdi-file-document</v-icon>
              </v-btn>
              <span>{{ file.fileName }}</span>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close"> {{ $_t("attribute.close") }} </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { fileDownloadUrlDynamic } from "@/tools/FileManager";

export default {
  name: "HomeWorkFilesModal",
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isShowVerifyModal: false,
    };
  },
  methods: {
    fileDownloadUrlDynamic,
    close() {
      this.isShowVerifyModal = false;
    },
  },
};
</script>

<style scoped></style>
