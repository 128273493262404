import { render, staticRenderFns } from "./ShowLessonDescription.vue?vue&type=template&id=9e5f818e&scoped=true&"
import script from "./ShowLessonDescription.vue?vue&type=script&lang=js&"
export * from "./ShowLessonDescription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e5f818e",
  null
  
)

export default component.exports