<template>
  <div>
    <v-card :loading="is_loading" class="mx-auto rounded-sm pa-5">
      <div
        v-if="student"
        class="d-flex flex-column flex-sm-row justify-space-between"
      >
        <span>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                {{ $_t("attribute.fullName") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ student.studentFullName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
        <span>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                {{ $_t("attribute.presentationProgress") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-progress-circular
                  class="my-2"
                  :rotate="-90"
                  :size="50"
                  :width="10"
                  :value="student.presentationProgress"
                  :color="'primary'"
                >
                  {{ Math.round(student.presentationProgress) }}
                </v-progress-circular>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
        <span>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                {{ $_t("attribute.studentTaskProgress") }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-progress-circular
                  class="my-2"
                  :rotate="-90"
                  :size="50"
                  :width="10"
                  :value="student.studentTaskProgress"
                  :color="'primary'"
                >
                  {{ Math.round(student.studentTaskProgress) }}
                </v-progress-circular>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
        <span>
          <!--          -->
          <v-btn
            width="11rem"
            height="2.5rem"
            color="primary"
            dark
            @click="printExams"
            class="float-end"
          >
            {{ $_t("attribute.export_exams") }}
          </v-btn>
        </span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ClassStudentInfo",
  computed: {
    ...mapGetters({
      students: "class/getStudents",
      student: "class/student",
      is_loading: "loading/is_loading",
    }),
    // student() {
    //   if (!this.students) return null;
    //   return this.students.find(
    //     (s) => s.studentPublicKey === this.$route.params.student_id
    //   );
    // },
  },
  methods: {
    printExams() {
      let exams = [];

      for (const exam of this.student.exams) {
        exams.push(exam);
      }

      //exams
      var mywindow = window.open("", "PRINT", "height=700,width=900");
      mywindow.document.write(
        "<title>" + `${this.student.studentFullName}-lessons` + "</title>"
      );
      mywindow.document.write("<div class='container'></div>");
      mywindow.document.write(
        "<style>\n" +
          "table {\n" +
          "  font-family: arial, sans-serif;\n" +
          "  border-collapse: collapse;\n" +
          "  width: 100%;\n" +
          "}\n" +
          "\n" +
          "td, th {\n" +
          "  border: 1px solid #dddddd;\n" +
          "  text-align: left;\n" +
          "  padding: 8px;\n" +
          "}\n" +
          "\n" +
          "tr:nth-child(even) {\n" +
          "  background-color: #dddddd;\n" +
          "}\n" +
          "</style>"
      );
      let start = mywindow.document.querySelector(".container");
      let table = mywindow.document.createElement("table");
      let row = mywindow.document.createElement("tr");
      let th = mywindow.document.createElement("th");
      let th2 = mywindow.document.createElement("th");
      let th3 = mywindow.document.createElement("th");
      let th4 = mywindow.document.createElement("th");
      let th5 = mywindow.document.createElement("th");
      th.innerText = "Exam destination";
      row.append(th);
      th2.innerText = "Exam state";
      row.append(th2);
      th3.innerText = "Exam score";
      row.append(th3);
      th4.innerText = "Exam feedback";
      row.append(th4);
      th5.innerText = "Exam type";
      row.append(th5);
      table.appendChild(row);

      for (const exam of exams.flat()) {
        let row2 = mywindow.document.createElement("tr");
        let td = mywindow.document.createElement("td");
        let td2 = mywindow.document.createElement("td");
        let td3 = mywindow.document.createElement("td");
        let td4 = mywindow.document.createElement("td");
        let td5 = mywindow.document.createElement("td");
        td.innerHTML = exam.destination;
        row2.appendChild(td);
        td2.innerHTML = exam.examState;
        row2.appendChild(td2);
        td3.innerHTML = exam.score;
        row2.appendChild(td3);
        td4.innerHTML = exam.examFeedback || "";
        row2.appendChild(td4);
        td5.innerHTML = this.$_t(`attribute.examTypes.${exam.type}`);
        row2.appendChild(td5);
        table.appendChild(row2);
      }

      start.appendChild(table);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      return true;
    },
  },
};
</script>

<style scoped></style>
