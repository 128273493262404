<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.edit_homework")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema[state]"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                v-if="!readonly"
              >
                {{ $_t("attribute.edit_homework") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import { mapGetters } from "vuex";
import { EDIT_HOMEWORK_SCHEMA } from "@/packages/admin/schema/form/EDIT_HOMEWORK_SCHEMA";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
const {
  required,
  numeric,
  minValue,
  maxValue,
} = require("vuelidate/lib/validators");

export default {
  name: "EditStudentHomework",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    homework: {
      type: [Object, Array, null, undefined],
      required: true,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: EDIT_HOMEWORK_SCHEMA.model,
      schema: EDIT_HOMEWORK_SCHEMA.schema,
      state: this.homework.state,
    };
  },
  validations() {
    if (this.state === "Pending") {
      return {
        form: {
          state: { required },
          correctPercentation: {},
          doneMoment: {},
          delay: {},
          feedback: {},
          sessionNumber: {},
        },
      };
    }
    if (this.state === "Done") {
      return {
        form: {
          state: { required },
          correctPercentation: {
            required,
            numeric,
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
          doneMoment: { required },
          delay: {},
          feedback: {},
          sessionNumber: {},
        },
      };
    }
    if (this.state === "WithOutHomeWork") {
      return {
        form: {
          state: { required },
          correctPercentation: {},
          doneMoment: {},
          delay: {},
          feedback: { required },
          sessionNumber: {},
        },
      };
    }
  },
  watch: {
    async isShowModal(val) {
      if (val) {
        this.form.feedback = this.homework?.homeworkFeedback;
        this.form.state = this.homework.state;
        this.form.correctPercentation = this.homework.correctPercentation
          ? this.homework.correctPercentation
          : null;
        this.form.doneMoment = this.homework.doneMoment
          ? this.homework.doneMoment.split("T")[0]
          : null;
        this.form.sessionNumber = null;

        if (this.homework.delay || this.homework.delay === 0) {
          this.form.delay = this.homework.delay
            ? this.homework.delay.toString() + " h"
            : this.homework.delay === 0
            ? 0
            : null;
          this.schema.Done[4].show = true;
        } else {
          this.schema.Done[4].show = false;
        }
        if (this.readonly) {
          this.schema[this.homework.state].forEach(
            (inp) => (inp.readonly = true)
          );
        }
      }
    },
    "form.state": function (value) {
      this.state = value;
      if (this.homework.delay || this.homework.delay === 0) {
        this.schema.Done[4].show = true;
        this.form.delay = this.homework.delay
          ? this.homework.delay.toString() + " h"
          : this.homework.delay === 0
          ? 0
          : null;
      } else {
        this.schema.Done[4].show = false;
      }
      this.$v.$reset();
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      student: "class/student",
      singleClass: "class/getClass",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        let payload = {
          classroomId: this.singleClass.classroomId,
          studentPublicKey: this.student.studentPublicKey,
          homeworkId: this.homework.homeworkId,
          state: this.form.state,
          doneMoment: null,
          correctPercentation: null,
          homeworkFeedback: this.form.feedback,
          sessionNumber: this.form.sessionNumber
            ? Number(this.form.sessionNumber)
            : null,
        };
        if (this.form.state === "Done") {
          payload.doneMoment = `${this.form.doneMoment}T00:01:01`;
          payload.correctPercentation = parseFloat(
            this.form.correctPercentation
          );
        }
        const res = await this.$actions.editStudentHomework(payload);
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.$v.$reset();
      this.form.doneMoment = null;
    },
  },
};
</script>

<style scoped></style>
