<template>
  <v-dialog
    width="1200px"
    v-model="modal"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.editStudentInfo")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="modal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ClassStudentInfo />
          </v-col>
          <v-col cols="12">
            <ClassStudentHomeworks />
          </v-col>
          <v-col cols="12">
            <ClassStudentExam />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ClassStudentInfo from "@/packages/admin/components/classRoom/details/ClassStudentInfo";
import ClassStudentHomeworks from "@/packages/admin/components/classRoom/details/ClassStudentHomeworks";
import ClassStudentExam from "@/packages/admin/components/classRoom/details/ClassStudentExams";
import { mapGetters } from "vuex";
export default {
  name: "EditStudentInfoModal",
  components: { ClassStudentExam, ClassStudentHomeworks, ClassStudentInfo },
  computed: {
    modal: {
      get() {
        return this.$store.getters["dialog/student"];
      },
      set(value) {
        this.$store.commit("dialog/setStudent", value);
        this.$router.push({ query: null });
      },
    },
    ...mapGetters({
      student: "class/student",
    }),
  },
};
</script>

<style scoped></style>
