<template>
  <v-row>
    <v-col cols="12">
      <v-row class="mb-5">
        <v-col cols="12" md="6">
          <v-text-field
            hide-details
            dense
            outlined
            v-model="search"
            :label="$_t('attribute.search')"
          />
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $_t("attribute.lessonName") }}
              </th>
              <th class="text-left">{{ $_t("attribute.title") }}</th>
              <th class="text-left">
                {{ $_t("attribute.teachingTimeInHour") }}
              </th>
              <th class="text-left">
                {{ $_t("attribute.homework") }}
              </th>
              <th class="text-left">
                {{ $_t("attribute.homeworkFiles") }}
              </th>
              <th class="text-left">
                {{ $_t("attribute.description") }}
              </th>
              <!--              <th class="text-left">-->
              <!--                {{ $_t("attribute.progress_percentage") }}-->
              <!--              </th>-->
              <!--              <th class="text-left">-->
              <!--                {{ $_t("attribute.reviewState") }}-->
              <!--              </th>-->
              <!--              <th class="text-left">-->
              <!--                {{ $_t("attribute.teachState") }}-->
              <!--              </th>-->
              <!--              <th class="text-left">-->
              <!--                {{ $_t("attribute.edit") }}-->
              <!--              </th>-->
              <!--              <th class="text-left">-->
              <!--                {{ $_t("attribute.delete") }}-->
              <!--              </th>-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lesson, i) in filteredLessons" :key="i">
              <td data-label="Name">{{ lesson.lessonName }}</td>
              <td data-label="Title">{{ lesson.lessonTitle }}</td>
              <td data-label="Teaching time">
                {{ `${lesson.teachingLessonTimeInHour} h` }}
              </td>
              <td data-label="Homework">
                <div class="d-flex flex-fill">
                  <AddNewHomeworkForStudents
                    @update="$emit('update')"
                    :lesson="lesson"
                  >
                    <template v-slot:activator>
                      <v-btn color="primary" small outlined fab class="mr-2"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                  </AddNewHomeworkForStudents>

                  <v-btn
                    :disabled="!passedLessons.includes(lesson.key)"
                    color="primary"
                    small
                    fab
                    @click="addHomework(lesson)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                </div>
              </td>
              <td>
                <HomeWorkFilesModal
                  v-if="lesson.homeworkFiles?.length"
                  :files="lesson.homeworkFiles"
                >
                  <template #activator>
                    <v-btn color="primary">{{ $_t("attribute.files") }}</v-btn>
                  </template>
                </HomeWorkFilesModal>
              </td>
              <td>
                <ShowLessonDescription
                  :description="lesson.description || ''"
                />
              </td>
              <!--              <td-->
              <!--                data-label="Teacher progress Percentage"-->
              <!--                style="height: 95px !important"-->
              <!--              >-->
              <!--                <v-progress-circular-->
              <!--                  class="my-2"-->
              <!--                  :rotate="-90"-->
              <!--                  :size="50"-->
              <!--                  :width="10"-->
              <!--                  :value="-->
              <!--                    singleClass.information[lesson.key]-->
              <!--                      .teacherProgressPercentation-->
              <!--                  "-->
              <!--                  :color="'primary'"-->
              <!--                >-->
              <!--                  {{-->
              <!--                    Math.round(-->
              <!--                      singleClass.information[lesson.key]-->
              <!--                        .teacherProgressPercentation-->
              <!--                    )-->
              <!--                  }}-->
              <!--                </v-progress-circular>-->
              <!--              </td>-->
              <!--              <td data-label="Review state">-->
              <!--                <v-btn-->
              <!--                  @click="-->
              <!--                    showInformation(-->
              <!--                      {-->
              <!--                        ...singleClass.information[lesson.key],-->
              <!--                        classroomId: $route.params.id,-->
              <!--                      },-->
              <!--                      'review'-->
              <!--                    )-->
              <!--                  "-->
              <!--                  :disabled="!passedLessons.includes(lesson.key)"-->
              <!--                  :color="'primary'"-->
              <!--                >-->
              <!--                  {{-->
              <!--                    $_t(-->
              <!--                      `attribute.${-->
              <!--                        singleClass.information[lesson.key].reviewedState-->
              <!--                      }`-->
              <!--                    )-->
              <!--                  }}-->
              <!--                  <v-icon class="ml-3">mdi-pencil</v-icon>-->
              <!--                </v-btn>-->
              <!--              </td>-->
              <!--              <td data-label="Teach state">-->
              <!--                <div>-->
              <!--                  <v-btn-->
              <!--                    @click="-->
              <!--                      showInformation(-->
              <!--                        {-->
              <!--                          ...singleClass.information[lesson.key],-->
              <!--                          classroomId: $route.params.id,-->
              <!--                        },-->
              <!--                        'teach'-->
              <!--                      )-->
              <!--                    "-->
              <!--                    :disabled="!passedLessons.includes(lesson.key)"-->
              <!--                    :color="'primary'"-->
              <!--                  >-->
              <!--                    {{-->
              <!--                      $_t(-->
              <!--                        `attribute.${-->
              <!--                          singleClass.information[lesson.key].teachState-->
              <!--                        }`-->
              <!--                      )-->
              <!--                    }}-->
              <!--                    <v-icon class="ml-3">mdi-pencil</v-icon>-->
              <!--                  </v-btn>-->
              <!--                </div>-->
              <!--              </td>-->
              <!--              <td>-->
              <!--                <v-btn-->
              <!--                  @click="-->
              <!--                    showEditLesson({-->
              <!--                      ...lesson,-->
              <!--                      classroomId: $route.params.id,-->
              <!--                      lessonId: lesson.key,-->
              <!--                      description: lesson.description,-->
              <!--                    })-->
              <!--                  "-->
              <!--                  icon-->
              <!--                  :color="'primary'"-->
              <!--                >-->
              <!--                  <v-icon>mdi-pencil</v-icon>-->
              <!--                </v-btn>-->
              <!--              </td>-->
              <!--              <td>-->
              <!--                <v-checkbox-->
              <!--                  color="red"-->
              <!--                  :value="lesson.key"-->
              <!--                  v-model="lessonsId"-->
              <!--                ></v-checkbox>-->
              <!--              </td>-->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <AddHomeworkToLesson
        :is-show-modal="homeworkModal"
        :lesson="lesson"
        @update="$emit('update')"
        @close="homeworkModal = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import AddNewHomeworkForStudents from "@/packages/admin/components/classRoom/details/modal/AddNewHomeworkForStudents";
import HomeWorkFilesModal from "@/packages/admin/components/classRoom/details/HomeWorkFilesModal";
import ShowLessonDescription from "@/packages/admin/components/lessons/ShowLessonDescription";
import { mapGetters } from "vuex";
import AddHomeworkToLesson from "@/packages/admin/components/classRoom/details/modal/AddHomeworkToLesson";
export default {
  name: "ClassLessonsTable",
  components: {
    AddHomeworkToLesson,
    ShowLessonDescription,
    HomeWorkFilesModal,
    AddNewHomeworkForStudents,
  },
  props: {
    singleClass: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      info: null,
      lesson: {},
      isShowReviewModal: false,
      isShowTeachModal: false,
      editLessonModal: false,
      homeworkModal: false,
      lessonsId: [],
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      passedLessons: "class/passedLessons",
      role: "authentication/role",
    }),
    lessons() {
      if (!this.singleClass) return [];
      let lessons = [];
      for (const key in this.singleClass.lessons) {
        lessons.push({ ...this.singleClass.lessons[key], key });
      }
      return lessons;
    },
    filteredLessons() {
      let filteredLessons = this.lessons;
      filteredLessons = this.search
        ? this.lessons.filter(
            (lesson) =>
              lesson.lessonName
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              lesson.lessonTitle
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              lesson.lessonSubTitle
                .toLowerCase()
                .includes(this.search.toLowerCase())
          )
        : filteredLessons;
      return filteredLessons;
    },
  },
  methods: {
    showInformation(info, state = "review") {
      this.info = info;
      if (state === "review") this.isShowReviewModal = true;
      if (state === "teach") this.isShowTeachModal = true;
    },
    showEditLesson(lesson) {
      this.lesson = lesson;
      this.editLessonModal = true;
    },
    addHomework(lesson) {
      console.log(lesson);
      this.lesson = lesson;
      this.homeworkModal = true;
    },
  },
};
</script>

<style scoped></style>
