<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.homework")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
          <v-progress-linear
            v-if="progress > 0"
            v-model="progress"
            color="primary"
          ></v-progress-linear>
          <v-container v-if="uploadedFiles?.length">
            <v-row>
              <v-col
                cols="12"
                v-for="file in uploadedFiles"
                :key="file.fileLink"
                class="d-flex justify-lg-space-between align-center"
              >
                <v-btn
                  @click="
                    fileDownloadUrlDynamic(file.fileLink, 'getHomeworkFile')
                  "
                  color="primary"
                  class="mr-4"
                >
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
                <span>{{ file.fileName }}</span>
                <v-spacer />
                <span>
                  <v-btn tile @click="deleteFile(file)" fab text
                    ><v-icon color="red">mdi-delete</v-icon></v-btn
                  >
                </span>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                v-if="!readonly"
              >
                {{ $_t("attribute.edit") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import { mapGetters } from "vuex";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { ADD_HOMEWORK_TO_LESSON } from "@/packages/admin/schema/classRoom/ADD_HOMEWORK_TO_LESSON.schema";
import { getMainTime } from "@/tools/Utils";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import { fileDownloadUrlDynamic } from "@/tools/FileManager";

export default {
  name: "AddHomeworkToLesson",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: ADD_HOMEWORK_TO_LESSON.model,
      schema: ADD_HOMEWORK_TO_LESSON.schema,
      getMainTime,
      uploadedFiles: [],
      progress: 0,
    };
  },
  validations: {
    ...ADD_HOMEWORK_TO_LESSON.validations,
  },
  watch: {
    async isShowModal(val) {
      if (val) {
        const list = await this.$actions.getSessionsOfLesson({
          classroomId: this.singleClass.classroomId,
          lessonId: this.lesson.key,
        });
        this.schema[this.getIdWithName(this.schema, "sessionNumber")].items =
          list.map((time, index) => ({
            text: `${this.$_date(time.start * 1000)} => ${getMainTime(
              time.start * 1000,
              "h:mm a"
            )} : ${getMainTime(time.end * 1000, "h:mm a")} `,
            value: ++index,
          }));
        this.form.homeworkDeadLine =
          this.lesson?.homeworkDeadLine.split("T")[0] || null;
        this.form.homeworkSubject = this.lesson?.homeworkSubject || null;
        this.uploadedFiles = this.lesson.homeworkFiles;
        if (this.readonly) {
          this.schema.forEach((inp) => (inp.readonly = true));
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      singleClass: "class/getClass",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    fileDownloadUrlDynamic,
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        let imgId = null;
        let fileName = null;
        if (this.form.homeworkFiles) {
          fileName = this.form.homeworkFiles.name;
          const formData = new FormData();
          formData.append("contractFile", this.form.homeworkFiles);
          const { data } = await api.post(
            urls.qs("uploadHomeworkFile", { name: fileName }),
            formData,
            {
              onUploadProgress: (progressEvent) => {
                this.progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              },
            }
          );
          imgId = data;
          // imgId = await this.$actions.uploadHomeworkFile(formData, fileName);
        }
        let payload = {
          classroomId: this.$route.params.id || this.singleClass.classroomId,
          lessonId: this.lesson.key,
          ...this.form,
          homeworkDeadLine: `${this.form.homeworkDeadLine}T00:01:01`,
          homeworkFiles: this.lesson?.homeworkFiles?.length
            ? [
                ...this.lesson.homeworkFiles,
                {
                  fileName,
                  fileLink: imgId,
                },
              ]
            : imgId
            ? [
                {
                  fileName,
                  fileLink: imgId,
                },
              ]
            : [],
        };
        console.log(payload);
        const res = await this.$actions.editLessonOfClass(payload);
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteFile(file) {
      console.log(file);
      if (!this.isFormValidate()) return;
      try {
        const res = await this.$actions.deleteHomeworkFile(file.fileName);
        if (res) {
          let payload = {
            classroomId: this.$route.params.id,
            lessonId: this.lesson.key,
            ...this.form,
            homeworkFiles: this.uploadedFiles.filter(
              (f) => f.fileName !== file.fileName
            ),
          };
          const res = await this.$actions.editLessonOfClass(payload);
          if (res) {
            this.$emit("update");
            this.close();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    clear() {
      this.form.homeworkSubject = null;
      this.form.homeworkDeadLine = null;
      this.form.sessionNumber = null;
      this.form.homeworkFiles = null;
      this.progress = 0;
    },
    close() {
      this.$emit("close");
      this.clear();
      this.$v.$reset();
    },
  },
};
</script>
