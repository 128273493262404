<template>
  <v-dialog
    width="1000px"
    v-model="modal"
    transition="dialog-bottom-transition"
  >
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.edit_presentation")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs centered>
            <v-tab>
              {{
                `${$_date(time.start * 1000)} => ${getMainTime(
                  time.start * 1000,
                  "h:mm a"
                )} : ${getMainTime(time.end * 1000, "h:mm a")} `
              }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card flat>
        <v-row>
          <v-col cols="12">
            <v-card-text
              v-for="([student, select, textarea], index) in forms"
              :key="student.studentPublicKey"
            >
              <p class="text-capitalize">
                {{ `${$_t("attribute.fullName")}: ${student.studentFullName}` }}
              </p>
              <v-row>
                <v-col cols="3">
                  <v-select
                    v-if="select.type === 'select'"
                    :label="$_t(`attribute.${select.label}`)"
                    @input="bindVal($event, 1, index)"
                    :value="select.value"
                    :readonly="select.readonly"
                    outlined
                    :items="select.items"
                    :error-messages="select.error"
                  ></v-select>
                </v-col>
                <v-col cols="9">
                  <v-textarea
                    v-if="textarea.type === 'textarea'"
                    outlined
                    @input="bindVal($event, 2, index)"
                    :label="$_t(`attribute.${textarea.label}`)"
                    :value="textarea.value"
                    :readonly="textarea.readonly"
                    :error-messages="textarea.error"
                  />
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
          </v-col>
        </v-row>
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close">
              {{ $_t("attribute.cancel") }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click="submit"
              :loading="is_loading"
              v-if="!readonly"
            >
              {{ $_t("attribute.edit") }}
            </v-btn>
          </div>
        </v-col>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";
import message from "@/tools/Message";
export default {
  name: "PresentInfoModal",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShowModal(value) {
      if (!value) return;
      this.singleClass.students.map((s) => {
        const status = s.presenceStatus.find(
          (s) =>
            s.availableTimeId === this.time.availableTimeId &&
            s.start === this.time.start &&
            s.end === this.time.end
        );
        this.forms.push([
          { ...s },
          {
            name: "isPresent",
            id: "isPresent",
            key: s.studentPublicKey,
            label: "isPresent",
            type: "select",
            cols: 4,
            error: null,
            readonly: this.readonly,
            value:
              typeof status?.isPresent === "boolean" ? status.isPresent : null,
            items: [
              {
                text: this.$_t("attribute.present"),
                value: true,
              },
              {
                text: this.$_t("attribute.absent"),
                value: false,
              },
            ],
          },
          {
            name: "feedBack",
            id: "feedBack",
            error: null,
            label: "feedBack",
            type: "textarea",
            readonly: this.readonly,
            value: status?.feedBack ? status.feedBack : null,
            cols: 8,
          },
        ]);
        console.log(this.forms);
      });
    },
  },
  data() {
    return {
      forms: [],
      getMainTime,
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      singleClass: "class/getClass",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        const payload = this.forms.map((row) => {
          if (row[1].value === null || !row[2].value) {
            message.error("Please fill the fields correctly");
            throw new Error("Please fill the fields correctly");
          }
          return {
            studentPublicKey: row[0].studentPublicKey,
            isPresent: row[1].value,
            feedBack: row[2].value,
          };
        });
        await this.$actions.presentInformation({
          studentPresenceInfo: payload,
          classroomId: this.$route.params.id || this.singleClass.classroomId,
          availableTimeId: this.time.availableTimeId,
          start: this.time.start,
          end: this.time.end,
        });

        this.$emit("update");
        this.close();
        if (this.next) {
          this.$emit("next");
        }
      } catch (e) {
        console.log(e);
      }
    },
    bindVal(value, id, index) {
      let input = this.forms[index][id];
      // if (value === "" || value === null)
      //   input.error = `${this.$_t(`attribute.${input.label}`)} is required`;
      // else input.error = null;
      input.value = value;
    },
    close() {
      this.forms = [];
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
