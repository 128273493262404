<template>
  <v-dialog v-model="isShowVerifyModal" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <v-btn fab color="red lighten-5" small class="elevation-0 mr-3">
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.delete") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 text-break">
          {{ $_t("attribute.delete_sure") }}
        </v-card-title>
        <v-card-text class="red--text">
          {{ $_t("attribute.action_is_permanent") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> {{ $_t("attribute.cancel") }} </v-btn>
          <v-btn color="red darken-1" dark @click="confirm">
            {{ $_t("attribute.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDelete",
  data() {
    return {
      isShowVerifyModal: false,
    };
  },
  methods: {
    open(){
      this.isShowVerifyModal = true;
    },
    cancel() {
      this.isShowVerifyModal = false;
    },
    confirm() {
      this.$emit("confirm");
      this.cancel();
    },
  },
};
</script>

<style scoped></style>
