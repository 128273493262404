<template>
  <div>
    <v-expansion-panels multiple tile flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>{{ $_t("attribute.homeworks") }}</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">
                      {{ $_t("attribute.sessionDate") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.sessionNumber") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.lessonTitle") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.state") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.deadLine") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.correctPercentation") }}
                    </th>
                    <th class="text-left">
                      {{ $_t("attribute.feedback") }}
                    </th>
                    <th class="text-left">
                      {{ `${$_t("attribute.delay")} (day)` }}
                    </th>
                    <th class="text-left">{{ $_t("attribute.edit") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(homework, i, key) in homeworks">
                    <tr v-if="singleClass.lessons[homework.lessonId]" :key="i">
                      <td data-label="#">{{ key + 1 }}</td>
                      <td data-label="start date">
                        {{
                          `${$_t("attribute.start_date")} : ${$_date(
                            homework.timeId.split("|")[0] * 1000
                          )} => ${getMainTime(
                            homework.timeId.split("|")[0] * 1000,
                            "h:mm a"
                          )} : ${getMainTime(
                            homework.timeId.split("|")[1] * 1000,
                            "h:mm a"
                          )} `
                        }}
                      </td>
                      <td data-label="Session number">
                        <!--                        {{ singleClass.lessons[homework.lessonId].lessonName }}-->
                        {{ homework.sessionNumber }}
                      </td>
                      <td data-label="lesson name">
                        {{ singleClass.lessons[homework.lessonId].lessonTitle }}
                      </td>
                      <td data-label="State">
                        {{ $_t(`attribute.${homework.state}`) }}
                      </td>
                      <td data-label="DeadLine">
                        {{
                          $_date(
                            homework.deadLine ||
                              singleClass.lessons[homework.lessonId]
                                .homeworkDeadLine,
                            "ISO"
                          )
                        }}
                      </td>
                      <td data-label="Correct perception">
                        <v-progress-circular
                          class="my-2"
                          :rotate="-90"
                          :size="50"
                          :width="10"
                          :value="homework.correctPercentation"
                          :color="'primary'"
                        >
                          {{ homework.correctPercentation }}
                        </v-progress-circular>
                      </td>
                      <td data-label="Feedback">
                        {{ homework.homeworkFeedback }}
                      </td>
                      <td data-label="delay">
                        {{ homework.delay }}
                      </td>
                      <td>
                        <v-btn
                          @click="
                            editHomework({
                              ...homework,
                              classroomId: $route.params.id,
                              studentPublicKey: $route.params.student_id,
                              homeworkId: i,
                            })
                          "
                          icon
                          :color="'primary'"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col></v-expansion-panel-content
        >
      </v-expansion-panel>
    </v-expansion-panels>
    <EditStudentHomework
      :is-show-modal="isShowHomeworkModal"
      :homework="homework"
      @close="cancel"
      @update="initClass"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditStudentHomework from "@/packages/admin/components/classRoom/details/modal/EditStudentHomework";
import { getMainTime } from "@/tools/Utils";

export default {
  name: "ClassStudentHomeworks",
  components: { EditStudentHomework },
  data() {
    return {
      homework: {},
      isShowHomeworkModal: false,
      getMainTime,
    };
  },
  computed: {
    ...mapGetters({
      student: "class/student",
      singleClass: "class/getClass",
    }),
    homeworks() {
      console.log(this.student);
      console.log(this.singleClass.lessons);
      if (!this.student) return null;
      return this.student.homeWorks;
    },
  },
  methods: {
    editHomework(homework) {
      this.homework = homework;
      this.isShowHomeworkModal = true;
    },
    cancel() {
      this.isShowHomeworkModal = false;
    },
    async initClass() {
      await this.$actions.getSingleClass({
        id: this.$route.params.id || this.singleClass.classroomId,
      });
      await this.$store.commit(
        "class/setStudent",
        this.singleClass.students.find(
          (s) => s.studentPublicKey === this.$route.query.studentPublicKey
        )
      );
    },
  },
};
</script>

<style scoped></style>
