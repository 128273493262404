import { _t } from "@/tools/Utils";

export const EDIT_HOMEWORK_SCHEMA = {
  /* Form SCHEMA */
  schema: {
    Pending: [
      {
        id: "state",
        label: "state",
        placeholder: "",
        show: true,
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "select",
        text: "text",
        value: "value",
        items: [
          { text: _t("attribute.Pending"), value: "Pending" },
          { text: _t("attribute.Done"), value: "Done" },
          { text: _t("attribute.WithOutHomeWork"), value: "WithOutHomeWork" },
        ],
      },
      {
        id: "feedback",
        label: "feedback",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "textarea",
      },
      {
        id: "sessionNumber",
        label: "sessionNumber",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "text",
        items: [],
      },
    ],
    Done: [
      {
        id: "state",
        label: "state",
        placeholder: "",
        show: true,
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "select",
        text: "text",
        value: "value",
        items: [
          { text: _t("attribute.Pending"), value: "Pending" },
          { text: _t("attribute.Done"), value: "Done" },
          { text: _t("attribute.WithOutHomeWork"), value: "WithOutHomeWork" },
        ],
      },
      {
        id: "correctPercentation",
        label: "correctPercentation",
        placeholder: "",
        cols: 12,
        lg: 6,
        md: 12,
        sm: 12,
        type: "text",
      },
      {
        id: "doneMoment",
        label: "doneMoment",
        placeholder: "",
        cols: 12,
        lg: 6,
        md: 12,
        sm: 12,
        type: "date",
      },
      {
        id: "delay",
        label: "delay",
        placeholder: "",
        readonly: true,
        show: false,
        cols: 12,
        lg: 6,
        md: 12,
        sm: 12,
        type: "text",
      },
      {
        id: "feedback",
        label: "feedback",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "textarea",
      },
      {
        id: "sessionNumber",
        label: "sessionNumber",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "text",
        items: [],
      },
    ],
    WithOutHomeWork: [
      {
        id: "state",
        label: "state",
        placeholder: "",
        show: true,
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "select",
        text: "text",
        value: "value",
        items: [
          { text: _t("attribute.Pending"), value: "Pending" },
          { text: _t("attribute.Done"), value: "Done" },
          { text: _t("attribute.WithOutHomeWork"), value: "WithOutHomeWork" },
        ],
      },
      {
        id: "feedback",
        label: "feedback",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "textarea",
      },
      {
        id: "sessionNumber",
        label: "sessionNumber",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "text",
        items: [],
      },
    ],
  },

  /* Form MODEL */
  model: {
    state: null,
    delay: null,
    correctPercentation: null,
    doneMoment: null,
    feedback: null,
    sessionNumber:null,
  },
};
