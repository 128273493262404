import { _t } from "@/tools/Utils";

export const lessons_array = [
  { name: _t("attribute.mathematics"), value: "mathematics" },
  { name: _t("attribute.physics"), value: "physics" },
  { name: _t("attribute.chemistry"), value: "chemistry" },
  { name: _t("attribute.biology"), value: "biology" },
  { name: _t("attribute.foreignLanguage"), value: "foreignLanguage" },
  { name: _t("attribute.economics"), value: "economics" },
  { name: _t("attribute.geography"), value: "geography" },
  { name: _t("attribute.computerScience"), value: "computerScienceICT" },
  { name: _t("attribute.financeAndAccounting"), value: "financeAndAccounting" },
  { name: _t("attribute.history"), value: "history" },
  { name: _t("attribute.psychology"), value: "psychology" },
  { name: _t("attribute.science"), value: "science" },
  { name: _t("attribute.interview"), value: "interview" },
  { name: _t("attribute.mat"), value: "mat" },
  { name: _t("attribute.tmua"), value: "tmua" },
  { name: _t("attribute.pat"), value: "pat" },
  { name: _t("attribute.engaa"), value: "engaa" },
  { name: _t("attribute.nsaa"), value: "nsaa" },
  { name: _t("attribute.bmat"), value: "bmat" },
  { name: _t("attribute.bmo"), value: "bmo" },
  { name: _t("attribute.smc"), value: "smc" },
  { name: _t("attribute.ukcho"), value: "ukcho" },
  { name: _t("attribute.c3l6"), value: "c3l6" },
  { name: _t("attribute.bpho"), value: "bpho" },
  { name: _t("attribute.bbo"), value: "bbo" },
  { name: _t("attribute.amc12"), value: "amc12" },
  { name: _t("attribute.aime"), value: "aime" },
  { name: _t("attribute.g5"), value: "g5" },
  { name: _t("attribute.toefl"), value: "toefl" },
  { name: _t("attribute.esat"), value: "esat" },
  { name: _t("attribute.others"), value: "others" },
];

export const lessons_filter = [
  { name: _t("attribute.all"), value: null },
  { name: _t("attribute.mathematics"), value: "mathematics" },
  { name: _t("attribute.physics"), value: "physics" },
  { name: _t("attribute.chemistry"), value: "chemistry" },
  { name: _t("attribute.biology"), value: "biology" },
  { name: _t("attribute.foreignLanguage"), value: "foreignLanguage" },
  { name: _t("attribute.economics"), value: "economics" },
  { name: _t("attribute.geography"), value: "geography" },
  { name: _t("attribute.computerScience"), value: "computerScienceICT" },
  { name: _t("attribute.financeAndAccounting"), value: "financeAndAccounting" },
  { name: _t("attribute.history"), value: "history" },
  { name: _t("attribute.psychology"), value: "psychology" },
  { name: _t("attribute.science"), value: "science" },
  { name: _t("attribute.interview"), value: "interview" },
  { name: _t("attribute.mat"), value: "mat" },
  { name: _t("attribute.tmua"), value: "tmua" },
  { name: _t("attribute.pat"), value: "pat" },
  { name: _t("attribute.engaa"), value: "engaa" },
  { name: _t("attribute.nsaa"), value: "nsaa" },
  { name: _t("attribute.bmat"), value: "bmat" },
  { name: _t("attribute.bmo"), value: "bmo" },
  { name: _t("attribute.smc"), value: "smc" },
  { name: _t("attribute.ukcho"), value: "ukcho" },
  { name: _t("attribute.c3l6"), value: "c3l6" },
  { name: _t("attribute.bpho"), value: "bpho" },
  { name: _t("attribute.bbo"), value: "bbo" },
  { name: _t("attribute.amc12"), value: "amc12" },
  { name: _t("attribute.aime"), value: "aime" },
  { name: _t("attribute.g5"), value: "g5" },
  { name: _t("attribute.toefl"), value: "toefl" },
  { name: _t("attribute.esat"), value: "esat" },
  { name: _t("attribute.others"), value: "others" },
];
export const admin_types = [
  { name: _t("attribute.mathematics"), value: "mathematics" },
  { name: _t("attribute.physics"), value: "physics" },
  { name: _t("attribute.chemistry"), value: "chemistry" },
  { name: _t("attribute.biology"), value: "biology" },
  { name: _t("attribute.foreignLanguage"), value: "foreignLanguage" },
  { name: _t("attribute.economics"), value: "economics" },
  { name: _t("attribute.geography"), value: "geography" },
  { name: _t("attribute.computerScience"), value: "computerScienceICT" },
  { name: _t("attribute.financeAndAccounting"), value: "financeAndAccounting" },
  { name: _t("attribute.history"), value: "history" },
  { name: _t("attribute.psychology"), value: "psychology" },
  { name: _t("attribute.science"), value: "science" },
  { name: _t("attribute.interview"), value: "interview" },
  { name: _t("attribute.mat"), value: "mat" },
  { name: _t("attribute.tmua"), value: "tmua" },
  { name: _t("attribute.pat"), value: "pat" },
  { name: _t("attribute.engaa"), value: "engaa" },
  { name: _t("attribute.nsaa"), value: "nsaa" },
  { name: _t("attribute.bmat"), value: "bmat" },
  { name: _t("attribute.bmo"), value: "bmo" },
  { name: _t("attribute.smc"), value: "smc" },
  { name: _t("attribute.ukcho"), value: "ukcho" },
  { name: _t("attribute.c3l6"), value: "c3l6" },
  { name: _t("attribute.bpho"), value: "bpho" },
  { name: _t("attribute.bbo"), value: "bbo" },
  { name: _t("attribute.amc12"), value: "amc12" },
  { name: _t("attribute.aime"), value: "aime" },
  { name: _t("attribute.g5"), value: "g5" },
  { name: _t("attribute.toefl"), value: "toefl" },
  { name: _t("attribute.esat"), value: "esat" },
  { name: _t("attribute.others"), value: "others" },
];
