<template>
  <p
    class="d-inline text-h5 font-weight-bold"
    :class="dark ? 'white--text' : 'black--text'"
  >
    {{ title }}
  </p>
</template>

<script>
export default {
  name: "TopTitle",
  props: {
    title: {
      required: true,
      type: String,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
