<template>
  <v-dialog width="450px" v-model="modal" transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card class="px-4">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">{{
            $_t("attribute.add_homework")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <MainSoftForm>
          <template v-slot:body>
            <MyFormGenerator
              lg="10"
              :schema="schema"
              :validator="$v"
              :enter="submit"
            ></MyFormGenerator>
          </template>
          <template v-slot:action>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ $_t("attribute.cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  class="ml-4"
                  @click="submit"
                  :loading="is_loading"
                >
                  {{ $_t("attribute.add") }}
                </v-btn>
              </div>
            </v-col>
          </template>
        </MainSoftForm>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ADD_NEW_HOMEWORK_SCHEMA } from "@/packages/admin/schema/form/ADD_NEW_HOMEWORK.schema";
import { getMainTime } from "@/tools/Utils";
export default {
  name: "AddNewHomeworkForStudents",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    lesson: {
      type: Object,
    },
  },
  data() {
    return {
      modal: false,
      schema: ADD_NEW_HOMEWORK_SCHEMA.schema,
      form: ADD_NEW_HOMEWORK_SCHEMA.model,
    };
  },
  watch: {
    modal(val) {
      if (val) {
        this.schema[this.getIdWithName(this.schema, "sessionNumber")].items =
          this.singleClass.times.map((time, index) => ({
            text: `${this.$_date(time.start * 1000)} => ${getMainTime(
              time.start * 1000,
              "h:mm a"
            )} : ${getMainTime(time.end * 1000, "h:mm a")} `,
            value: {
              sessionNumber: ++index,
              timeId: `${time.start}|${time.end}`,
            },
          }));
      } else {
        this.clear();
      }
    },
  },
  validations: {
    ...ADD_NEW_HOMEWORK_SCHEMA.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      singleClass: "class/getClass",
    }),
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      // console.log({
      //   classroomId: this.$route.params.id,
      //   lessonId: this.lesson.key,
      //   timeId: this.form.sessionNumber.timeId,
      //   sessionNumber: this.form.sessionNumber.sessionNumber,
      // });
      const res = await this.$actions.addNewHomeworkToStudents({
        classroomId: this.$route.params.id || this.singleClass.classroomId,
        lessonId: this.lesson.key,
        timeId: this.form.sessionNumber.timeId,
        sessionNumber: this.form.sessionNumber.sessionNumber,
      });
      if (res) {
        this.close();
        this.$emit("update");
      }
    },
    close() {
      this.modal = false;
    },
    clear() {
      this.form.sessionNumber = null;
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
