import { _t } from "@/tools/Utils";

export const EDIT_EXAM_SCHEMA = {
  schema: {
    Pending: [
      {
        id: "examState",
        label: "examState",
        placeholder: "",
        show: true,
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        value: "value",
        type: "select",
        items: [
          { text: _t("attribute.Pending"), value: "Pending" },
          { text: _t("attribute.Done"), value: "Done" },
          { text: _t("attribute.WithOutExam"), value: "WithOutExam" },
        ],
      },
      {
        id: "feedback",
        label: "feedback",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "textarea",
      },
    ],
    Done: [
      {
        id: "examState",
        label: "examState",
        placeholder: "",
        show: true,
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        value: "value",
        type: "select",
        items: [
          { text: _t("attribute.Pending"), value: "Pending" },
          { text: _t("attribute.Done"), value: "Done" },
          { text: _t("attribute.WithOutExam"), value: "WithOutExam" },
        ],
      },
      {
        id: "score",
        label: "score",
        placeholder: "",
        cols: 12,
        lg: 6,
        md: 12,
        sm: 12,
        type: "text",
      },
      {
        id: "doneMoment",
        label: "doneMoment",
        placeholder: "",
        cols: 12,
        lg: 6,
        md: 12,
        sm: 12,
        type: "date",
      },
      {
        id: "feedback",
        label: "feedback",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "textarea",
      },
    ],
    WithOutExam: [
      {
        id: "examState",
        label: "examState",
        placeholder: "",
        show: true,
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        value: "value",
        type: "select",
        items: [
          { text: _t("attribute.Pending"), value: "Pending" },
          { text: _t("attribute.Done"), value: "Done" },
          { text: _t("attribute.WithOutExam"), value: "WithOutExam" },
        ],
      },
      {
        id: "feedback",
        label: "feedback",
        placeholder: "",
        cols: 12,
        lg: 12,
        md: 12,
        sm: 12,
        type: "textarea",
      },
    ],
  },
  model: {
    score: null,
    examState: null,
    doneMoment: null,
    feedback: null,
  },
};
