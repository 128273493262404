<template>
  <div>
    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="$attrs.color || 'primary'"
          v-bind="attrs"
          v-on="on"
          outlined
          >{{ text || $_t("attribute.see_description") }}</v-btn
        >
      </template>

      <v-card class="pa-3">
        <v-textarea
          height="400"
          readonly
          no-resize
          full-width
          :value="description"
          hide-details
        />
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ $_t("attribute.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowLessonDescription",
  props: {
    description: {
      type: [String, null],
      required: true,
    },
    text: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>
