<template>
  <v-row>
    <v-col cols="12">
      <v-sheet :height="250">
        <v-toolbar flat>
          <TopTitle :title="$_t('attribute.upcoming_classes')"></TopTitle>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-4"
            fab
            small
            color="grey darken-2"
            @click="
              stateColor = null;
              init();
            "
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2 mx-4"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ $_t(`attribute.${type}`) }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{
                  $_t("attribute.day")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>
                  {{ $_t("attribute.week") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>
                  {{ $_t("attribute.month") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn outlined class="mx-4" color="grey darken-2" @click="setToday">
            {{ $_t("attribute.today") }}
          </v-btn>
          <v-btn color="primary" class="px-0 mr-3" @click="prev">
            <v-icon large> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn color="primary" class="px-0 mr-4" @click="next">
            <v-icon large> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-select
                hide-details
                outlined
                :label="$_t('attribute.classes')"
                dense
                :items="classList"
                multiple
                v-model="classId"
                @change="init"
                clearable
                :item-text="
                  (item) =>
                    `${item.classroomName} | ${$_t(
                      `attribute.${item.classroomLevel}`
                    )}`
                "
                item-value="classroomId"
              >
                <template v-slot:prepend-item>
                  <div class="px-3 py-2">
                    <v-text-field
                      outlined
                      :label="$_t('attribute.search')"
                      dense
                      hide-details
                      @input="fetchClasses($event)"
                    />
                  </div>
                </template>
                <template v-slot:selection="{ index, item }">
                  <v-chip color="primary" v-if="index < 3" class="my-2">
                    <span>{{ truncateString(item.classroomName, 10) }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    (+{{ classId.length - 3 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" lg="4" v-if="!['Teacher'].includes(role)">
              <v-select
                hide-details
                outlined
                :label="$_t('attribute.filter_by_teacher')"
                dense
                :items="teachers"
                v-model="teacherPublicKey"
                @change="init"
                multiple
                clearable
                :item-text="(item) => `${item.fullName} | ${item.nickName}`"
                item-value="publicKey"
              >
                <template v-slot:prepend-item>
                  <div class="px-3 py-2">
                    <v-text-field
                      outlined
                      :label="$_t('attribute.searchTeacher')"
                      dense
                      hide-details
                      @input="fetchTeachers($event)"
                    />
                  </div>
                </template>
                <template v-slot:selection="{ index, item }">
                  <v-chip color="primary" v-if="index < 4" class="my-2">
                    <span>{{ truncateString(item.fullName, 10) }}</span>
                  </v-chip>
                  <span v-if="index === 4" class="grey--text text-caption">
                    (+{{ teacherPublicKey.length - 4 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4" v-if="!['Teacher'].includes(role)">
              <v-select
                hide-details
                flat
                outlined
                class="elevation-0"
                background-color="inputColor"
                :label="$_t('attribute.filter_by_consultants')"
                dense
                :items="consultants"
                v-model="consultantPublicKey"
                @change="init"
                clearable
                :item-text="(item) => `${item.fullName} | ${item.nickName}`"
                item-value="publicKey"
              >
              </v-select>
            </v-col>
            <v-col cols="6" v-if="!['Teacher'].includes(role)">
              <v-select
                hide-details
                outlined
                :label="$_t('attribute.filter_by_students')"
                dense
                :items="students"
                multiple
                @change="init"
                v-model="studentsPublicKeys"
                clearable
                :item-text="(item) => `${item.fullName} | ${item.nickName}`"
                item-value="publicKey"
              >
                <template v-slot:prepend-item>
                  <div class="px-3 py-2">
                    <v-text-field
                      outlined
                      :label="$_t('attribute.search')"
                      dense
                      hide-details
                      @input="fetchStudents($event)"
                    />
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                hide-details
                outlined
                :label="$_t('attribute.baseType')"
                dense
                :items="lessons"
                v-model="lesson"
                @change="init"
                clearable
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <!--          from to export -->
            <v-col cols="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="range"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="range"
                    :label="$_t('attribute.export')"
                    dense
                    readonly
                    hide-details
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="range"
                  no-title
                  range
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="exportRange">
                    {{ $_t("attribute.export") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>

        <ClassStateColor
          @filterState="setStateColor"
          :activeColor="stateColor"
        />
      </v-sheet>
      <v-sheet v-if="events">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          @change="updateRange"
          :locale="$i18n.locale"
          :type="type"
          :events="events"
          :event-color="getEventColor"
        >
          <template v-slot:day-header="{ date }">
            <div class="d-flex">
              <v-tooltip color="primary" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-auto"
                    v-on="on"
                    v-bind="attrs"
                    fab
                    dark
                    outlined
                    small
                    color="primary"
                    @click="exportTodayClasses(date)"
                  >
                    <v-icon>mdi-export</v-icon>
                  </v-btn>
                </template>
                <span>{{ $_t("attribute.exportTodayClasses") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:event="{ event, eventSummary }">
            <div @click="eventDetails(event)" class="pa-1" style="height: 100%">
              <div>
                {{ `${$_t("attribute.room")}: ${event.classroomNumber}` }}
              </div>
              <div>
                {{ `${event.classroomName}` }}
              </div>
              <div v-if="event">
                {{ `${$_t("attribute.teacher")}: ${event.teacherName}` }}
              </div>
              <div v-if="event">
                {{ `${$_t(`attribute.${event.baseType}`)}` }}
              </div>
              <div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <v-dialog v-model="showDetails" width="1000">
      <v-card v-if="event">
        <v-toolbar color="primary" dark flat>
          <div class="d-flex flex-wrap" style="column-gap: 0.5rem">
            <v-toolbar-items>
              {{ `${$_t(`attribute.${event.baseType}`)}` }}
            </v-toolbar-items>

            <v-toolbar-items>
              {{ `${event.teacherName}` }}
            </v-toolbar-items>
            <br />

            <v-toolbar-items>
              {{ `${$_t("attribute.room")}: ${event.classroomNumber}` }}
            </v-toolbar-items>

            <v-toolbar-items>
              {{ `${$_t("attribute.start")}: ${$_date(event.start, "long")}` }}
            </v-toolbar-items>

            <v-toolbar-items>
              {{ `${$_t("attribute.end")}: ${$_date(event.end, "long")}` }}
            </v-toolbar-items>

            <v-toolbar-items>
              {{ `${event.classroomName}` }}
            </v-toolbar-items>
          </div>

          <v-btn color="white" class="ml-auto" outlined @click="closeDetail">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs align-with-title v-model="tab">
              <v-tabs-slider color="white"></v-tabs-slider>

              <v-tab v-for="item in tabs" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item key="actions">
            <v-card flat>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-btn
                      color="primary"
                      block
                      :disabled="event?.color === colors.reserved"
                      @click="showPresetModal"
                    >
                      {{ $_t("attribute.add_present_info") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn
                      block
                      color="primary"
                      :disabled="event?.color === colors.reserved"
                      @click="showTeachingDayModal"
                    >
                      {{ $_t("attribute.edit_teaching_day_info") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    v-for="(student, index) in event.students"
                    :key="index"
                  >
                    <v-btn
                      @click="openStudentModal(student)"
                      :disabled="event?.color === colors.reserved"
                      block
                      color="primary"
                      class="px-7 elevation-0"
                    >
                      <div class="txt-purple">
                        {{
                          `${student.studentFullName} ${$_t(
                            "attribute.student_exams_homeworks"
                          )}`
                        }}
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="
                      [
                        'Admin',
                        'SchedulingAdmin',
                        'Teacher',
                        'TeamLeader',
                      ].includes(role)
                    "
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      block
                      color="primary"
                      :disabled="
                        event.changesConfirmedByTeacher ||
                        event.state === 'Cancel'
                      "
                      @click="
                        confirmTime({
                          ...event,
                          role: 'teacher',
                          type: 'confirm',
                        })
                      "
                    >
                      {{ $_t("attribute.confirmedByTeacher") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="['Admin', 'SchedulingAdmin'].includes(role)"
                    cols="12"
                    md="6"
                  >
                    <v-btn
                      block
                      color="primary"
                      :disabled="
                        event.changesConfirmedBySchedulingAdmin ||
                        event.state === 'Cancel'
                      "
                      @click="
                        confirmTime({
                          ...event,
                          role: 'admin',
                          type: 'confirm',
                        })
                      "
                    >
                      {{ $_t("attribute.confirmedByAdmin") }}
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="['Admin', 'SchedulingAdmin'].includes(role)"
                    cols="12"
                  >
                    <v-btn
                      block
                      color="primary"
                      @click="
                        confirmTime({ ...event, role: 'admin', type: 'revert' })
                      "
                      :disabled="
                        !event.changesConfirmedByTeacher ||
                        event.state === 'Cancel'
                      "
                    >
                      {{ $_t("attribute.revert") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      outlined
                      @click="showDetail(event)"
                    >
                      {{ $_t("attribute.details") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item key="lessons">
            <v-card flat>
              <v-container>
                <ClassLessonsTable
                  @update="
                    update;
                    getPassedLessonsOfClass;
                  "
                  :single-class="singleClass"
                />
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item key="report">
            <v-card flat>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="primary" @click="generateReportLink">{{
                      $_t("attribute.generateReportLink")
                    }}</v-btn>
                  </v-col>
                  <v-col cols="12" v-if="event.linksExpireDate">
                    {{
                      `Link will expire at ${$_date(
                        event.linksExpireDate,
                        "long"
                      )}`
                    }}
                  </v-col>
                  <v-col
                    cols="12"
                    v-for="id in event.studentsReportLink"
                    :key="id.oneTimeLink"
                  >
                    <v-text-field
                      outlined
                      readonly
                      hide-details
                      :disabled="!id.oneTimeLink"
                      :value="
                        id.oneTimeLink
                          ? `${origin}/parent/report/${id.oneTimeLink}`
                          : 'Not generated yet'
                      "
                      :label="`${id.studentFullName} parent report link`"
                      prepend-icon="mdi-content-copy"
                      @click:prepend="
                        copyToClipboard(
                          `${origin}/parent/report/${id.oneTimeLink}`
                        )
                      "
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <PresentInfoModal
      v-if="event"
      :time="{
        availableTimeId: event.availableTimeId,
        start: event.start / 1000,
        end: event.end / 1000,
      }"
      next
      :is-show-modal="presentInfoModal"
      :readonly="event.changesConfirmedByTeacher"
      @close="close"
      @update="update"
      @next="showTeachingDayModal"
    />
    <TeachingDayInfo
      v-if="event"
      :time="{
        availableTimeId: event.availableTimeId,
        start: event.start / 1000,
        end: event.end / 1000,
      }"
      :is-show-modal="teachingDayModal"
      @close="close"
      @update="update"
      :readonly="event.changesConfirmedByTeacher"
    />
    <EditStudentInfoModal />
    <ConfirmClassSessionModal
      :is-show-modal="confirmModal"
      :time="time"
      @close="confirmModal = false"
      @update="update"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { lessons_filter } from "@/packages/admin/schema/calender/LESSONS_ARRAY";
import PresentInfoModal from "@/packages/admin/components/classRoom/details/modal/PresentInfoModal";
import TeachingDayInfo from "@/packages/admin/components/classRoom/details/modal/TeachingDayInfo";
import ClassStateColor from "@/packages/admin/components/classRoom/ClassStateColor";
import EditStudentInfoModal from "@/packages/admin/components/classRoom/details/EditStudentInfoModal";
import { utils, writeFileXLSX } from "xlsx";
import { getMainTime } from "@/tools/Utils";
import TopTitle from "@/components/app/TopTitle";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";
import ConfirmClassSessionModal from "@/packages/admin/components/classRoom/details/modal/ConfirmClassSessionModal";
import ClassLessonsTable from "@/packages/admin/components/classRoom/details/ClassLessonsTable";

export default {
  name: "ClassesCalendar",
  components: {
    ClassLessonsTable,
    ConfirmClassSessionModal,
    TopTitle,
    EditStudentInfoModal,
    ClassStateColor,
    TeachingDayInfo,
    PresentInfoModal,
  },
  data() {
    return {
      focus: this.$route.query?.start || "",
      type: "week",
      events: [],
      event: null,
      showDetails: false,
      lessons: lessons_filter,
      lesson: null,
      teacherPublicKey: [],
      presentInfoModal: false,
      teachingDayModal: false,
      classList: [],
      classId: [],
      confirmModal: false,
      time: {},
      getMainTime,
      menu: false,
      range: null,
      colors: event_colors,
      consultantPublicKey: null,
      studentsPublicKeys: [],
      stateColor: null,
      start: null,
      end: null,
      tabs: ["actions", "lessons", "report"],
      tab: "actions",
    };
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      students: "admin/get_users",
      role: "authentication/role",
      teachers: "admin/get_teachers",
      singleClass: "class/getClass",
      consultants: "admin/get_consultants",
    }),
    origin() {
      return window.origin;
    },
  },
  methods: {
    showDetail(item) {
      const routeData = this.$router.resolve({
        name: "admin.class_details",
        params: { id: item.classroomId },
      });
      window.open(routeData.href, "_blank");
    },
    async init() {
      const { events } = await this.$actions.getFilteredClasses({
        start: this.start,
        end: this.end,
        filterByLessonType: this.lesson,
        filterByTeacher:
          this.role === "Teacher"
            ? [this.user.publicKey]
            : this.teacherPublicKey,
        filterByClassroom: this.classId,
        filterByStudent: this.studentsPublicKeys,
        filterByAcademicConsultant: this.consultantPublicKey,
        all: true,
      });
      this.events = events;
      this.filterByState();
    },
    async fetchTeachers(search) {
      await this.$actions.getUsers({
        all: true,
        role: "Teacher",
        search,
        dispatch: "admin/setTeachers",
        ignoreRoleFilter: ["FinancialAdmin", "AssistantTeacher"].includes(
          this.role
        ),
      });
    },
    async fetchConsultants(search) {
      await this.$actions.getUsers({
        all: true,
        role: "AcademicConsultant",
        search,
        dispatch: "admin/setAcademicConsultant",
        ignoreRoleFilter: [
          "AcademicConsultant",
          "FinancialAdmin",
          "TeamLeader",
          "SchedulingAdmin",
          "AssistantTeacher",
        ].includes(this.user.role),
      });
    },
    async fetchStudents(search) {
      await this.$actions.getUsers({
        all: true,
        role: "Student",
        dispatch: "admin/setUsers",
        search,
        ignoreRoleFilter: ["FinancialAdmin", "AssistantTeacher"].includes(
          this.role
        ),
      });
    },
    setStateColor(color) {
      this.stateColor = color;
      this.init();
    },
    filterByState() {
      if (!this.stateColor) return;
      this.events = this.events.filter((e) => e.color === this.stateColor);
    },
    async fetchClasses(search) {
      const { classes } = await this.$actions.getClasses({
        all: true,
        role: this.role,
        search,
      });
      this.classList = classes;
    },
    openStudentModal(item) {
      this.$router.push({ query: { studentPublicKey: item.studentPublicKey } });
      this.$store.commit("class/setStudent", item);
      this.$store.commit("dialog/setStudent", true);
    },
    getEventColor(event) {
      return event.color;
    },
    async eventDetails(event) {
      console.log(event);
      if (event.state === "Cancel") return;
      this.event = event;
      await this.$store.dispatch(`class/setClass`, event.singleClass);
      // get passed lessons
      await this.getPassedLessonsOfClass();
      this.showDetails = true;
    },
    async update() {
      this.showDetails = false;
      await this.$actions.getSingleClass({
        id: this.singleClass.classroomId || this.event.singleClass.classroomId,
      });
      await this.init();
    },
    async getPassedLessonsOfClass() {
      await this.$actions.getPassedLessonsOfClass({
        classroomId:
          this.singleClass.classroomId || this.event.singleClass.classroomId,
      });
    },
    async generateReportLink() {
      const res = await this.$actions.generateReportForParents({
        classroomId: this.event.classroomId,
        availableTimeId: this.event.availableTimeId,
        start: this.event.start / 1000,
        end: this.event.end / 1000,
      });
      if (res) await this.update();
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showPresetModal() {
      this.presentInfoModal = true;
    },
    closeDetail() {
      this.showDetails = false;
      this.event = null;
    },
    close() {
      this.presentInfoModal = false;
      this.teachingDayModal = false;
    },
    showTeachingDayModal() {
      this.teachingDayModal = true;
    },
    confirmTime(time) {
      this.time = time;
      this.confirmModal = true;
    },
    getColorState(color) {
      switch (color) {
        case this.colors.canceled:
          return this.$_t("attribute.classWithNoInformationOrPresentation");
        case this.colors.passed:
          return this.$_t("attribute.noConfirmation");
        case this.colors.new:
          return this.$_t("attribute.noConfirmation");
        case this.colors.waiting_for_class:
          return this.$_t("attribute.confirmedByAdmin");
        case this.colors.cancel:
          return this.$_t("attribute.canceled");
        case this.colors.reserved:
          return this.$_t("attribute.reservedClass");
      }
    },
    exportTodayClasses(today) {
      let today_events = this.events
        .filter((event) => today === this.$_date(event.start, "ISO"))
        .map((e) => ({
          teacher: e.teacherName,
          classroomName: e.classroomName,
          level: e.classroomLevel,
          lesson: this.$_t(`attribute.${e.baseType}`),
          classroomNumber: e.classroomNumber,
          date: this.getMainTime(e.start, "MM-DD-YYYY"),
          start: this.getMainTime(e.start, "H:mm"),
          end: this.getMainTime(e.end, "H:mm"),
          students: e.students.map((s) => s.studentFullName).join(", "),
          state: this.getColorState(e.color),
        }));
      console.log(today_events);
      const ws = utils.json_to_sheet(today_events);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `${today}.xlsx`);
    },
    async exportRange() {
      this.$refs.menu.save(this.range);
      const startMS = new Date(`${this.range[0]} 00:00`).getTime();
      const endMS = new Date(`${this.range[1]} 23:59`).getTime();
      const { events } = await this.$actions.getFilteredClasses({
        start: startMS / 1000,
        end: endMS / 1000,
        all: true,
      });
      const sessions = events
        .filter((event) => startMS <= event.start && event.end <= endMS)
        .map((e) => ({
          teacher: e.teacherName,
          classroomName: e.classroomName,
          level: e.classroomLevel || "",
          lesson: this.$_t(`attribute.${e.baseType}`),
          classroomNumber: e.classroomNumber,
          date: this.getMainTime(e.start, "MM-DD-YYYY"),
          start: this.getMainTime(e.start, "H:mm"),
          end: this.getMainTime(e.end, "H:mm"),
          students: e.students.map((s) => s.studentFullName).join(", "),
          state: this.getColorState(e.color),
        }));
      const ws = utils.json_to_sheet(sessions);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `${this.range[0]} | ${this.range[1]}.xlsx`);
    },
    async updateRange({ start, end }) {
      const viewStart = this.$refs.calendar.getStartOfWeek(start);
      const startDate = new Date(viewStart.date).getTime();

      let viewEnd = this.$refs.calendar.getEndOfWeek(end);
      const endDate = new Date(`${viewEnd.date}T23:59:59`).getTime();
      this.start = startDate / 1000;
      this.end = endDate / 1000;
      await this.$router
        .push({
          query: {
            start: this.$_date(startDate, "ISO"),
            end: this.$_date(endDate, "ISO"),
          },
        })
        .catch(() => false);
      await this.init();
    },
    async copyToClipboard(url) {
      try {
        await navigator.clipboard.writeText(url);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
  async mounted() {
    // await this.$actions.getAllUsers({
    //   roles: ["Teacher", "Student", "AcademicConsultant"],
    //   dispatches: [
    //     "admin/setTeachers",
    //     "admin/setUsers",
    //     "admin/setAcademicConsultant",
    //   ],
    //   ignoreRoleFilter: ["AcademicConsultant", "FinancialAdmin"].includes(
    //     this.role
    //   ),
    // });
    await this.fetchClasses();
    await this.fetchTeachers();
    await this.fetchConsultants();
    await this.fetchStudents();
  },
};
</script>

<style scoped></style>
