<template>
  <v-dialog
    width="1000px"
    v-model="modal"
    transition="dialog-bottom-transition"
  >
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.edit_teaching_day_info")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs centered>
            <v-tab>
              {{
                `${$_date(time.start * 1000)} => ${getMainTime(
                  time.start * 1000,
                  "h:mm a"
                )} : ${getMainTime(time.end * 1000, "h:mm a")} `
              }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card flat v-if="singleClass">
        <v-textarea
          no-resize
          outlined
          v-model="classDescription"
          :readonly="readonly"
          :label="$_t('attribute.description')"
          class="mt-3"
        />
        <v-list flat>
          <v-subheader>{{ $_t("attribute.lessons_report") }}</v-subheader>
          <v-row class="mb-5 ml-1">
            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                dense
                outlined
                :label="$_t('attribute.search')"
                @input="filter({ search: $event })"
              />
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center">
              {{
                `${$_t("attribute.classMinutes")}: ${classMinutes} ${$_t(
                  "attribute.minutes"
                )}`
              }}
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="d-flex align-center"
              :class="{ 'red--text': classMinutes !== timeSummation }"
            >
              {{
                `${$_t("attribute.timeSummation")}: ${timeSummation} ${$_t(
                  "attribute.minutes"
                )}`
              }}
            </v-col>
          </v-row>

          <v-list-item-group color="primary">
            <!--            free -->
            <v-list-item>
              <v-list-item-icon class="">
                <v-checkbox
                  :readonly="readonly"
                  v-model="free.isSelected"
                ></v-checkbox>
              </v-list-item-icon>
              <v-list-item-content class="pt-4 pb-0">
                <v-text-field
                  dense
                  :hint="$_t('attribute.otherContentInfo')"
                  v-model.trim="free.otherContent"
                  :label="$_t('attribute.otherContent')"
                  outlined
                  :readonly="readonly"
                />
                <v-list-item-subtitle>
                  <div class="text--disabled"></div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="mt-6">
                <v-text-field
                  type="number"
                  outlined
                  :disabled="!free.isSelected"
                  dense
                  :readonly="readonly"
                  hide-details
                  :label="$_t('attribute.teach_minutes')"
                  v-model="free.teachingMinutes"
                />
              </v-list-item-icon>
            </v-list-item>
            <!--            form-->
            <v-list-item
              v-for="lesson in filteredLessons"
              :key="lesson.lessonId"
            >
              <v-list-item-icon>
                <v-checkbox
                  :readonly="readonly"
                  v-model="lesson.isSelected"
                ></v-checkbox>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    `${lesson.lessonName} | ${lesson.teachingLessonTimeInHour} h`
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="text--disabled">
                    {{ lesson.lessonTitle }} | {{ lesson.lessonSubTitle }} |
                    {{ $_t(`attribute.${lesson.lessonLevel}`) }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-text-field
                  type="number"
                  outlined
                  :disabled="!lesson.isSelected"
                  :readonly="readonly"
                  dense
                  hide-details
                  :label="$_t('attribute.teach_minutes')"
                  v-model="lesson.teachingMinutes"
                />
              </v-list-item-icon>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close">
              {{ $_t("attribute.cancel") }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click="submit"
              :loading="is_loading"
              v-if="!readonly"
            >
              {{ $_t("attribute.edit") }}
            </v-btn>
          </div>
        </v-col>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { getMainTime } from "@/tools/Utils";
import { mapGetters } from "vuex";
import message from "@/tools/Message";

export default {
  name: "TeachingDayInfo",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: 0,
      getMainTime,
      lessonForm: [],
      filteredLessons: [],
      classDescription: null,
      free: {
        otherContent: "",
        teachingMinutes: 0,
        isSelected: false,
      },
      classMinutes: 0,
    };
  },
  watch: {
    isShowModal(val) {
      if (val) {
        let info =
          this.singleClass?.teachingDayInformation.find(
            (info) =>
              info.availableTimeId === this.time.availableTimeId &&
              info.start === this.time.start &&
              info.end === this.time.end
          ) || null;
        console.log(info);
        if (info) {
          this.classDescription = info.description;
        }
        for (const id in this.singleClass.lessons) {
          let lessonInfo = null;
          if (info) {
            lessonInfo =
              info?.lessonsInformation.find((l) => l.lessonId === id) || null;
          }
          if (lessonInfo) {
            this.lessonForm.push({
              ...this.singleClass.lessons[lessonInfo.lessonId],
              lessonId: lessonInfo.lessonId,
              teachingMinutes: lessonInfo.teachingMinutes,
              isSelected: true,
            });
          } else {
            this.lessonForm.push({
              ...this.singleClass.lessons[id],
              lessonId: id,
              teachingMinutes: 0,
              isSelected: false,
            });
          }
        }
        //free content bind section
        // const free =
        //   info?.lessonsInformation.find((l) => "otherContent" in l) || null;
        // if (free) {
        //   this.free.isSelected = !!free.otherContent;
        //   this.free.otherContent = free.otherContent;
        //   this.free.teachingMinutes = free.teachingMinutes;
        // }

        this.classMinutes = (this.time.end - this.time.start) / 60;

        this.filteredLessons = this.lessonForm;
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      singleClass: "class/getClass",
    }),
    timeSummation() {
      if (!this.free.isSelected)
        return this.filteredLessons
          .map((l) => parseFloat(l.teachingMinutes))
          .reduce((partialSum, a) => partialSum + a, 0);
      else
        return (
          this.filteredLessons
            .map((l) => parseFloat(l.teachingMinutes))
            .reduce((partialSum, a) => partialSum + a, 0) +
          parseFloat(this.free.teachingMinutes)
        );
    },
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    filter({ search = null }) {
      let filteredLessons = this.lessonForm;
      filteredLessons = search
        ? this.lessonForm.filter(
            (lesson) =>
              lesson.lessonName.toLowerCase().includes(search.toLowerCase()) ||
              lesson.lessonTitle.toLowerCase().includes(search.toLowerCase())
          )
        : filteredLessons;
      console.log(filteredLessons);
      this.filteredLessons = filteredLessons;
    },
    async submit() {
      try {
        if (!this.classDescription || this.classDescription === "")
          return message.error("Please write some description");

        let lessonsInformation = this.lessonForm
          .filter((l) => l.isSelected)
          .map((l) => {
            return {
              lessonId: l.lessonId,
              teachingMinutes: parseFloat(l.teachingMinutes),
              otherContent: null,
            };
          });
        if (
          this.free.isSelected &&
          this.free.otherContent != null &&
          this.free.otherContent !== ""
        ) {
          lessonsInformation.push({
            lessonId: `${Math.floor(Math.random() * 1000 + Date.now())}`,
            teachingMinutes: parseFloat(this.free.teachingMinutes),
            otherContent: this.free.otherContent,
          });
        }

        if (!lessonsInformation.length)
          return message.error("Please select a lesson");

        if (this.timeSummation !== this.classMinutes)
          return message.error("Your time is not equal to class time");

        //check sum minutes is equal to class minutes
        let payload = {
          classroomId: this.$route.params.id || this.singleClass.classroomId,
          availableTimeId: this.time.availableTimeId,
          start: this.time.start,
          end: this.time.end,
          description: this.classDescription,
          lessonsInformation,
        };
        console.log(payload);
        const res = await this.$actions.setTeachingDayInfo(payload);
        if (res) this.$emit("update");
        this.close();
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.lessonForm = [];
      this.classDescription = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
