<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.edit_exam")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema[state]"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
        </template>
        <template v-slot:action>
          <v-col cols="12" v-if="!readonly">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
              >
                {{ $_t("attribute.edit_exam") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card></v-dialog
  >
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { EDIT_EXAM_SCHEMA } from "@/packages/admin/schema/form/EDIT_EXAM_SCHEMA";
import {
  decimal,
  required,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
export default {
  name: "EditStudentExam",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    exam: {
      type: Object,
      required: true,
    },
    isShowModal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      state: this.exam.examState,
      schema: EDIT_EXAM_SCHEMA.schema,
      form: EDIT_EXAM_SCHEMA.model,
    };
  },
  validations() {
    let form = {
      examState: { required },
      score: {},
      doneMoment: {},
      feedback: {},
    };
    if (this.state === "Done") {
      form.score.required = required;
      form.score.decimal = decimal;
      form.score.maxValue = maxValue(100);
      form.score.minValue = minValue(0);
      form.doneMoment.required = required;
    }
    return {
      form: { ...form },
    };
  },
  watch: {
    async isShowModal(val) {
      if (val) {
        this.form.examState = this.exam.examState;
        this.form.doneMoment = this.exam.doneMoment
          ? this.exam.doneMoment?.split("T")[0]
          : null;
        this.form.score = this.exam.score ? this.exam.score : null;
        this.form.feedback = this.exam?.examFeedback;
        if (this.readonly) {
          this.schema[this.exam.examState].forEach(
            (inp) => (inp.readonly = true)
          );
        }
      }
    },
    "form.examState": function (value) {
      this.state = value;
      this.$v.$reset();
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      student: "class/student",
      singleClass: "class/getClass",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.isFormValidate()) return;
        let payload = {
          classroomId: this.singleClass.classroomId,
          studentPublicKey: this.student.studentPublicKey,
          examId: this.exam.examId,
          examState: this.form.examState,
          score: null,
          doneMoment: null,
          examFeedback: this.form.feedback,
        };
        if (this.form.examState === "Done") {
          payload.doneMoment = `${this.form.doneMoment}T00:01:01`;
          payload.score = parseFloat(this.form.score);
        }
        const res = await this.$actions.editStudentExam(payload);
        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
      this.$v.$reset();
      this.form.doneMoment = null;
    },
  },
};
</script>

<style scoped></style>
